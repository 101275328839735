import React from 'react';

const Footer = () => (
  <footer id="kontakt" className="Footer">
    <div className="Footer__lines">
      <div className="Footer__line" />
      <div className="Footer__line" />
    </div>

    <div className="Footer__text Footer__thanks">Děkuji a těším se na případnou spolupráci s Vámi</div>

    <h3 className="Footer__title">Autodoprava – Zemní práce</h3>
    <h3 className="Footer__title">Zdeněk Kaprálek</h3>

    <address>
      <div className="Footer__text">Tyršova 581</div>
      <div className="Footer__text">Mnichovice, 251 64</div>
      <div className="Footer__text">IČ: 13279602 DIČ: CZ6802110953</div>
      <div className="Footer__text">
        tel.:
        <a href="tel:+420608246994" className="Footer__phone">608 246 994</a>
      </div>
      <div className="Footer__text">
        e-mail:
        <a href="mailto:kapr.doprava@seznam.cz" className="Footer__email">kapr.doprava@seznam.cz</a>
      </div>
    </address>

    <a href="https://michalhonc.cz" className="Footer__author" rel="author">
      ©
      {new Date().getFullYear()}
      {' '}
      Michal Honc
    </a>

  </footer>
);

export default Footer;
