export default [
  {
    title: 'Technika',
    to: '/#technika',
  },
  {
    title: 'Reference',
    to: '/reference',
  },
  {
    title: 'Ceník',
    to: '/cenik',
  },
  {
    title: 'Kontakt',
    to: '#kontakt',
    class: 'Header--link__white',
  },
];
