import React, { useState } from 'react';
import classnames from 'classnames';
import { navigate } from 'gatsby';

import menuData from '../js/menu';

const MobileNav = ({ toggleMenu }) => {
  const [isExtended, setIsExtended] = useState(false);

  const handleOnMenuClick = (e) => {
    e.preventDefault();
    setIsExtended(prevIsExtended => !prevIsExtended);
    toggleMenu();
  };

  const handleOnLinkClick = (e) => {
    e.preventDefault();
    setIsExtended(false);
    toggleMenu();
    navigate(e.target.dataset.gatsbyTo);
  };


  return (
    <>
      <div
        className={classnames('Header--button',
          isExtended ? 'Header--button__opened' : 'Header--button__closed')}
        onClick={handleOnMenuClick}
      >
        <span />
        <span />
        <span />
      </div>
      <div className={classnames('Header--mobileListWrapper', isExtended && 'Header--mobileListWrapper__extended')}>
        <ul className="Header--mobileList">
          {menuData.map(menuItem => (
            <li
              className="Header--mobileLink"
              key={menuItem.title}
              onClick={handleOnLinkClick}
              data-gatsby-to={menuItem.to}
            >
              {menuItem.title}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MobileNav;
