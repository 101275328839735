import React from "react";
import PropTypes from "prop-types";
import { Link, useStaticQuery, graphql } from "gatsby";
import classnames from "classnames";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";

import Nav from "./nav";
import videoMP4 from "../media/video/video.mp4";
import videoMP4Mobile from "../media/video/video-mobile.mp4";
import videoWEBM from "../media/video/video.webm";
import videoWEBMMobile from "../media/video/video-mobile.webm";

const Header = ({ siteTitle, path }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const toggleMenu = () => setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "images/video-background.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FIXED)
        }
      }
    }
  `);
  console.log(data);

  return (
    <header className={classnames("Header", isMenuOpen && "Header--open")}>
      <Nav toggleMenu={toggleMenu} />
      <div className="Header__background">
        {path === "root" ? (
          <>
            <video className="Header__background-video" autoPlay muted loop>
              <source src={videoMP4} type="video/mp4" />
              <source src={videoWEBM} type="video/webm" />
            </video>
            <video
              className="Header__background-video Header__background-video--mobile"
              autoPlay
              muted
              loop
            >
              <source src={videoMP4Mobile} type="video/mp4" />
              <source src={videoWEBMMobile} type="video/webm" />
            </video>
          </>
        ) : (
          <Img
            className="Header__background-image"
            image={getImage(data.placeholderImage)}
            layout="fixed"
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "",
};

export default Header;
