import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";

import Header from "./header";
import Footer from "./footer";
import "../styles/main.scss";

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header path={path} siteTitle={data.site.siteMetadata.title} />
      <div className={cx("Container", path && `Container__${path}`)}>
        <div className="Line" />
        <main>{children}</main>

        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
