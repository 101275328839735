import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import logoIMG from '../media/images/logo.png';
import menuData from '../js/menu';
import MobileNav from './mobileNav';

const Nav = ({ toggleMenu }) => (
  <nav className="Header--nav">
    <div className="Header--container">
      <Link to="/">
        <img src={logoIMG} className="Header--logo" />
      </Link>

      <ul className="Header--list">
        {menuData.map(menuItem => (
          <li className={classnames('Header--link', menuItem.class)} key={menuItem.title}>
            <Link to={menuItem.to}>{menuItem.title}</Link>
          </li>
        ))}
      </ul>

      <MobileNav toggleMenu={toggleMenu} />
    </div>
  </nav>
);

export default Nav;
